export default {
  methods: {
    contentLink(e) {
      const link = e.target.closest("a")?.href;
      if (link) {
        const url = new URL(link);
        if (window.location.host === url.host && !url.hash) {
          e.preventDefault();
          this.$router.push(this.localePath(url.pathname + url.search));
        }
      }
    },
  },
};