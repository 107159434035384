
import { mapGetters, mapState } from "vuex";
import { getLocalStoragePopups, setLocalStoragePopups } from "~/utils";
import contentLinkMixin from "~/mixins/contentLink.js";

export default {
  name: "MarketingPopup",
  mixins: [contentLinkMixin],
  computed: {
    ...mapGetters({
      appBreakpoints: "general/getAppBreakpoints",
    }),
    ...mapState({
      sitePopup: (state) => state.general.sitePopup,
    }),
    showPopup: {
      get() {
        return Boolean(this.sitePopup);
      },
      set(val) {
        this.$store.commit("general/SET_SITE_POPUP", val);
      },
    },
  },
  watch: {
    showPopup: {
      handler(value) {
        if (value) {
          document.addEventListener("keyup", this.onKeyUp);
        } else {
          document.removeEventListener("keyup", this.onKeyUp);
        }
      },
    },
  },
  methods: {
    closeModal() {
      const storePopups = getLocalStoragePopups();

      setLocalStoragePopups(
        storePopups.map((popup) => {
          return popup.id === this.sitePopup.id
            ? {
                ...popup,
                last_opened_at: new Date(),
              }
            : popup;
        })
      );

      this.showPopup = null;
    },
    contentClick(e) {
      const link = e.target.closest("a")?.href;
      if (link) {
        const url = new URL(link);
        if (window.location.host === url.host && !url.hash) {
          e.preventDefault();
          this.$router.push(this.localePath(url.pathname + url.search));
          this.closeModal();
        }
      }
    },
    onKeyUp(evt) {
      if (evt.keyCode === 27) {
        this.closeModal();
      }
    },
  },
};
